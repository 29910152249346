import PedidoWebRepository from "@/repositories/v2/PedidoWebRepository";
import { ArmazenagemParametrosListagemPedidos } from "../ArmazenagemParametrosListagemPedidos";
import { statusPedido } from "./statusPedido";
import store from "@/store/store";

export default class ListagemPedidosService {
  vm;
  status;
  armazenagemParametrosListagemPedidos =
    ArmazenagemParametrosListagemPedidos.build();
  loading = false;
  pedidoWebRepository = PedidoWebRepository.build();

  constructor(armazenagemParametrosListagemPedidos, pedidoWebRepository) {
    this.armazenagemParametrosListagemPedidos =
      armazenagemParametrosListagemPedidos;
    this.pedidoWebRepository = pedidoWebRepository;
  }

  static build() {
    const armazenagemParametrosListagemPedidos =
      ArmazenagemParametrosListagemPedidos.build();
    const pedidoWebRepository = PedidoWebRepository.build();
    return new ListagemPedidosService(
      armazenagemParametrosListagemPedidos,
      pedidoWebRepository
    );
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  setStatus() {
    this.status = this.vm.pedidosStatus;
    return this;
  }

  async list(page = 0, filtro) {
    const temFiltroAtual = (() => {
      for (const f of Object.keys(filtro || {})) {
        if (filtro[f]) {
          return true;
        }
      }
      return false;
    })();
    // console.log({
    //   telaTemFiltros: this.vm.telaTemFiltros,
    //   temFiltroAtual: temFiltroAtual,
    //   filtro: filtro,
    // });
    if (!this.vm.telaTemFiltros && temFiltroAtual) {
      page = 1;
    }
    this.vm.telaTemFiltros = temFiltroAtual;
    if (page > 0) {
      this.vm.pedidos = [];
    }
    switch (this.status) {
      case statusPedido.todosPedidos:
        await this.getTodosPedidos(page || 1, filtro || {});
        break;
      case statusPedido.aguardandoProducao:
        await this.getPedidosAguardandoProducao(page || 1, filtro || {});
        break;
      case statusPedido.emLinhaDeProducao:
        await this.getPedidosEmLinhaProducao(page || 1, filtro || {});
        break;
      case statusPedido.despachados:
        await this.getPedidosDespachados(page || 1, filtro || {});
        break;
      case statusPedido.cancelado:
        await this.getPedidosCancelados(page || 1, filtro || {});
        break;
      case statusPedido.conferenciaAc:
        await this.getPedidosEmConferenciaAc(page || 1, filtro || {});
        break;
      case statusPedido.aguardandoEmbarque:
        await this.getPedidosEmAguardandoEmbarque(page || 1, filtro || {});
        break;
      case statusPedido.aguardandoConferencia:
        await this.getPedidosAguardandoConferencia(page || 1, filtro || {});
        break;
      case statusPedido.emConferencia:
        await this.getPedidosEmConferencia(page || 1, filtro || {});
        break;
      case statusPedido.paraManutencao:
        await this.getPedidosParaManutencao(page || 1, filtro || {});
        break;
      case statusPedido.emLinhaDeProducaoDiversos:
        await this.getPedidosEmLinhaProducaoDiversos(page || 1, filtro || {});
        break;
      case statusPedido.conferenciaComProblema:
        await this.getPedidosConferenciaComProblema(page || 1, filtro || {});
        break;
      case statusPedido.naoExpedir:
        await this.getPedidosNaoExpedir(page || 1, filtro || {});
        break;
      default:
        break;
    }
  }

  async requestPedidosWeb(params) {
    try {
      switch (this.status) {
        case statusPedido.emLinhaDeProducaoDiversos:
          return await this.pedidoWebRepository.getPedidosDiversosList(params);
        case statusPedido.naoExpedir:
          return await this.pedidoWebRepository.getPedidosNaoExpedir(params);
        default:
          return await this.pedidoWebRepository.getPedidosWebList(params);
      }
    } catch (e) {
      console.error(e.message);
      return { status: 500, data: { list: [] } };
    }
  }
  async requestPedidoPeloSerial(params) {
    try {
      return await this.pedidoWebRepository.getPedidoPeloSerial(params);
    } catch (e) {
      console.error(e.message);
      return { status: 500, data: { list: [] } };
    }
  }
  async getPedidos(page, filtro, params) {
    this.loading = true;
    this.vm.pedidos = [];
    this.vm.pagination = {};
    try {
      const response = await this.requestPedidosWeb({
        ...filtro,
        ...params,
        perPage: 10,
        page,
        spt: "false",
        unidadeId: store.state.menu.selectedFilial.value || 1,
      });

      // console.log("Responseabaixo");
      // console.log(response);
      this.loading = false;
      if (response.status !== 200) return;
      const { data } = response.data;
      const { pagination, list } = data;
      this.vm.pedidos = list;
      this.vm.pagination = pagination;
    } catch (e) {
      console.error({ e });
      this.loading = false;
    }
  }

  async getTodosPedidos(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "",
      web: true,
      ploomes: true,
      express: true,
      fixador: true,
    });
  }

  async getPedidosAguardandoProducao(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "A",
      web: true,
    });
  }

  async getPedidosEmLinhaProducao(page, filtro) {
    await this.getPedidos(page, filtro, { status: "L", web: true });
  }

  async getPedidosDespachados(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "D",
      web: true,
      ploomes: true,
    });
  }

  async getPedidosCancelados(page, filtro) {
    await this.getPedidos(page, filtro, { status: "C" });
  }

  async getPedidosEmConferenciaAc(page, filtro) {
    await this.getPedidos(page, filtro, {
      conferencia_ac: "true",
      web: true,
      ploomes: true,
    });
  }

  async getPedidosEmAguardandoEmbarque(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "E",
      web: true,
      ploomes: true,
    });
  }

  async getPedidosAguardandoConferencia(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "N",
      web: true,
      ploomes: true,
    });
  }

  async getPedidosEmConferencia(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "F",
      web: true,
      ploomes: true,
    });
  }

  async getPedidosParaManutencao(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "A",
      web: true,
      ploomes: true,
    });
  }

  async getPedidosEmLinhaProducaoDiversos(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "O",
      ploomes: true,
    });
  }

  async getPedidosConferenciaComProblema(page, filtro) {
    await this.getPedidos(page, filtro, {
      status: "T",
      web: true,
      ploomes: true,
    });
  }
  async getPedidosNaoExpedir(page, filtro) {
    await this.getPedidos(page, filtro, {});
  }

  async getPedidoPeloSerial(serial) {
    this.vm.pedido = null;
    try {
      const response = await this.requestPedidoPeloSerial({ serial });
      if (response.status !== 200) {
        this.vm.pedido = false;
        this.vm.isLoading = false;
        return;
      }
      const { data } = response.data;
      this.vm.pedido = data[0];
      this.vm.isLoading = false;
      this.vm.serialString = serial;
    } catch (e) {
      console.error({ e });
    }
  }
}
