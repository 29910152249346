export default {
  getDrawer(state) {
    return state.drawer;
  },
  
  getDarkMode(state) {
    return state.darkMode;
  },

  getEnvironment(state) {
    return state.environment;
  },

  getFilial(state) {
    return state.selectedFilial;
  },

  getFiliaisList(state) {
    return state.filiaisList;
  },
};
