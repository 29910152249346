export default {
  setListing(state, payload) {
    state.listing = payload;
  },
  setOrderOverview(state, payload) {
    state.orderOverview = payload;
  },
  setStageDuration(state, payload) {
    state.stageDuration = payload;
  },
  setInputDetails(state, payload) {
    state.inputDetails = payload;
  },
  setVolumeDetails(state, payload) {
    state.volumeDetails = payload;
  },
  setSortingDetails(state, payload) {
    state.sortingDetails = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setUrlParams(state, payload) {
    state.urlParams = payload;
  },
  setIsLoadingListing(state, payload) {
    state.isLoadingListing = payload;
  },
  setCurrentMainTabIndex(state, payload) {
    state.currentMainTabIndex = payload;
  },
  setCurrentSubTabIndex(state, payload) {
    state.currentSubTabIndex = payload;
  },
  resetState(state, payload) {
    Object.assign(state, payload);
  },
};
