import { HttpRestService } from "@/services/http/HttpRestService";
import store from "@/store/store";

export class FirstLineRepository {
  static build() {
    return new FirstLineRepository();
  }

  getLocalizacaoEstoque() {
    return HttpRestService.get(`/api/local-estoque`);
  }

  getUser() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(`/api/user-sep-linha1?unidadeId=${unidadeId} `);
  }

  getPedidos(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/paineis`
    );
  }
}
