import { HttpRestService } from "@/services/http/HttpRestService";
import store from "@/store/store";

export default class UsersRepository {
  static build() {
    return new UsersRepository();
  }

  getUsers() {
    return HttpRestService.get(`/api/users`);
  }

  getUser(id) {
    return HttpRestService.get(`/api/users/${id}`);
  }

  postUser(body) {
    return HttpRestService.post(`/api/v2/usuario/cadastrar`, body);
  }

  editUser(id, body) {
    return HttpRestService.put(`/api/v2/usuario/editar${id}`, body);
  }

  deleteUser(id) {
    return HttpRestService.post(`/api/v2/usuario/delete/${id}`);
  }

  getFuncoes() {
    return HttpRestService.get(`/api/funcs`);
  }

  getFuncoesByUser(userId) {
    return HttpRestService.get(`/api/user-func/${userId}`);
  }

  getUsuariosSeparadoresLinhaPaineis() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(`/api/user-sep-linha1?unidadeId=${unidadeId} `);
  }

  getFuncoesBegin(body) {
    return HttpRestService.post(`/api/pedido/begin-func`, body);
  }
}
