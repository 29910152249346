<template>
  <default-modal
    :value="value"
    @input="$emit('input', $event)"
  >
    <template v-slot:header>
      <v-card-title class="justify-center font-weight-bold pt-0"
        >Etiquetas de SKU</v-card-title
      >
      <v-container>
        <v-form
          ref="form"
          @submit.prevent
        >
          <v-row @keydown.enter="generateEtiquetas">
            <v-col cols="12"
              ><default-text-field
                v-model="codigoProduto"
                autofocus
                :hide-details="false"
                label="SKU"
                :rules="[rules.required]"
                class="mb-n4"
            /></v-col>
            <v-col cols="12"
              ><default-text-field
                v-model="quantidade"
                label="Quantidade de Etiquetas"
                :hide-details="false"
                type="number"
                min="1"
                :rules="[rules.required, rules.isValid]"
                class="mb-n4"
            /></v-col>
            <v-col
              cols="8"
              class="mx-auto"
            >
              <default-button
                @click="generateEtiquetas"
                :loading="isLoading"
                >Gerar Etiquetas (.pdf)</default-button
              >
            </v-col>
          </v-row></v-form
        >
      </v-container>
    </template></default-modal
  >
</template>

<script>
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultModal from "@/shared/components/vuetify/DefaultModal.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";

export default {
  name: "EtiquetasSku",
  components: {
    DefaultButton,
    DefaultModal,
    DefaultTextField,
  },
  data() {
    return {
      isLoading: false,
      codigoProduto: "",
      quantidade: "",
    };
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    rules() {
      return {
        required: (input) => !!input || "Campo obrigatório",
        isValid: (input) => /^[1-9]\d*$/.test(input) || "Quantidade inválida",
      };
    },
  },
  methods: {
    generateEtiquetas() {
      if (this.$refs.form.validate()) {
        this.$emit("generateEtiquetas", {
          body: {
            codigoProduto: Number(this.codigoProduto),
            quantidade: Number(this.quantidade),
          },
          setIsLoading: this.setIsLoading,
        });
      }
    },
    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },
  },
};
</script>

<style scoped></style>
