import Swal from "sweetalert2";
import { setJwtToken } from "../jwtToken";
import router from "../../router";
import { HttpRestService } from "../http/HttpRestService";
import { UsuarioLogado } from "./UsuarioLogado";
import store from "@/store/store";

export class LoginService {
  vm;
  event;
  response;

  static build() {
    return new LoginService();
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  setEvent(event) {
    this.event = event;
    return this;
  }

  async login() {
    try {
      await this.tryLogin();
    } catch (e) {
      console.error({ e });
      await this.showUnauthorizedMessage();
    }
  }

  async tryLogin() {
    this.vm.loading = true;
    this.event.preventDefault();
    this.validate();
    const response = await HttpRestService.post(
      "/api/v2/login",
      this.getBodyRequest()
    );
    console.log(response);
    console.log(this.vm);
    this.vm.loading = false;
    if (response.status !== 200) {
      await this.showUnauthorizedMessage();
      return;
    }

    this.response = response;
    this.storeLoginData();
    await router.replace({ name: "dashboard" });
    window.location.reload();
  }

  validate() {
    if (this.vm.password.length <= 0) {
      this.vm.loading = false;
      throw new Error("invalid_parameters_for_login");
    }
  }

  getBodyRequest() {
    const { email, password } = this.vm;
    return { email, password };
  }

  async showUnauthorizedMessage() {
    await Swal.fire({
      icon: "error",
      text: "Acesso não autorizado.",
    });
  }

  storeLoginData() {
    setJwtToken(this.response.data.data.token);
    // window.Laravel.isLoggedin = true;
    // window.Laravel.user = { ID: this.response.data.data.id, ROLES: this.response.data.data.roles };
    // setUserLogged(this.response.data.data.id, this.response.data.data.pcEmprId, this.response.data.data.roles);
    UsuarioLogado.setUserId(this.response.data.data.id);
    UsuarioLogado.setPcEmprId(this.response.data.data.pcEmprId);
    UsuarioLogado.setRoles(this.response.data.data.roles);
    UsuarioLogado.setPermissoes(this.response.data.data.permissoes);
    UsuarioLogado.setPerfil(this.response.data.data.perfilNome);
    UsuarioLogado.setUnidadeId(this.response.data.data.unidadeId);
    const unidadeId = this.response.data.data.unidadeId;

    //Alterar caso seja necessário adicionar mais unidades - Erick Serejo - 05.02.2025
    store.state.menu.selectedFilial.label =
      unidadeId === 1 ? "Matriz - Ceará" : "Filial - Pará";
    store.state.menu.selectedFilial.value = unidadeId;
  }
}

export const redirectToLoginScreen = async () => {
  await router.replace({ path: "/login" });
};
