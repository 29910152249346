import Vue from "vue";
import { compileToFunctions } from "vue-template-compiler";
import VueRouter from "vue-router";
import NewRoles from "../modules/funcoes/New";
import EditRoles from "../modules/funcoes/Edit";
import NewUsers from "../modules/usuarios/New";
import EditUsers from "../modules/usuarios/Edit";
import NewLocal from "../modules/localizacao_estoque/New";
import EditLocal from "../modules/localizacao_estoque/Edit";
import PedidoConfere from "../modules/souserials/producao_pedido/conferencia/PedidoConfere";
import PedidoConfereAC from "../modules/souserials/producao_pedido/conferencia/PedidoConfereAC";
import PedidoConfereDet from "../modules/souserials/producao_pedido/conferencia/PedidoConfereDet";
// import ConfereComProblema from "../modules/souserials/producao_pedido/conferencia/ConfereComProblema";
import PedidoResumo from "../modules/souserials/producao_pedido/expedicao_pedido/PedidoResumo";
import PedidoEmbarque from "../modules/souserials/producao_pedido/expedicao_pedido/PedidoEmbarque";
import PedidoWebProducaoLinhaPaineis from "../modules/souserials/producao_pedido/linhas_producao/PedidoWebProducaoLinhaPaineis";
import PedidoWebProducaoSelecaoLinhas from "../modules/souserials/producao_pedido/selecao_linhas/PedidoWebProducaoSelecaoLinhas";
import PedidoWebProducaoLinhaCabos from "../modules/souserials/producao_pedido/linhas_producao/PedidoWebProducaoLinhaCabos";
import PedidoWebProducaoLinhaDiversos from "../modules/souserials/producao_pedido/linhas_producao/PedidoWebProducaoLinhaDiversos";
import PedidoWebProducaoLinhaPerfis from "../modules/souserials/producao_pedido/linhas_producao/PedidoWebProducaoLinhaPerfis";
import PedidoWebProducaoLinhaInversores from "../modules/souserials/producao_pedido/linhas_producao/PedidoWebProducaoLinhaInversores";
import ListingComponent from "../modules/souserials/listagem_pedidos/ListingComponent.vue";
import GerenciarPedido from "../modules/gerenciar_pedido/view/GerenciarPedido.vue";
import EscolheLinhaEditarPedido from "../modules/souserials/producao_pedido/linhas_producao/EditarLinhas/EscolheLinhaEditarPedido";
import EditarLinhaPaineis from "../modules/souserials/producao_pedido/linhas_producao/EditarLinhas/EditarPaineis/EditarLinhaPaineis";
import EditarLinhaDiversos from "../modules/souserials/producao_pedido/linhas_producao/EditarLinhas/EditarDiversos/EditarLinhaDiversos";
// import AgendamentoExpress from "../modules/express/listagem_pedidos_manutencao/Manutencao.vue";
import ListingComponentExpress from "../modules/express/listagem_pedidos/ListingComponent.vue";
import PedidoWebProducaoSelecaoLinhasExpress from "../modules/express/producao_pedido/selecao_linhas/PedidoWebProducaoSelecaoLinhas.vue";
import PedidoWebProducaoLinhaPaineisExpress from "../modules/express/producao_pedido/linhas_producao/PedidoWebProducaoLinhaPaineis.vue";
import PedidoWebProducaoLinhaCabosExpress from "../modules/express/producao_pedido/linhas_producao/PedidoWebProducaoLinhaCabos.vue";
import PedidoWebProducaoLinhaDiversosExpress from "../modules/express/producao_pedido/linhas_producao/PedidoWebProducaoLinhaDiversos.vue";
import PedidoWebProducaoLinhaPerfisExpress from "../modules/express/producao_pedido/linhas_producao/PedidoWebProducaoLinhaPerfis.vue";
// import PedidoWebProducaoLinhaInversoresExpress from "../modules/express/producao_pedido/linhas_producao/PedidoWebProducaoLinhaInversoresExpress.vue";
import PedidoConfereExpress from "../modules/express/producao_pedido/conferencia/PedidoConfere.vue";
import PedidoConfereACExpress from "../modules/express/producao_pedido/conferencia/PedidoConfereAC.vue";
import PedidoEmbarqueExpress from "../modules/express/producao_pedido/expedicao_pedido/PedidoEmbarque.vue";
import { getUserLogged } from "../services/userLogged";
import store from "@/store/store";

Vue.use(VueRouter);

export const sidebarViews = [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("../modules/dashboard/view/DashboardOld.vue"),
    meta: {
      viewName: "Dashboard",
      sidebarIcon: "mdi-view-dashboard-outline",
      hasVuetify: true,
    },
  },
  {
    name: "Administração",
    path: "/administracao",
    props: { status: "" },
    component: ListingComponent,
    meta: {
      permission: "tela-de-administracao",
      sidebarIcon: "mdi-clipboard-text-outline",
      hasVuetify: true,
    },
  },
  {
    name: "Triagem",
    path: "/triagem",
    component: Vue.component("Triagem", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      sidebarIcon: "mdi-clipboard-search-outline",
      isSubmenu: true,
    },
    children: [
      // {
      //   name: "Conferência de Triagem",
      //   path: "conferencia-de-triagem",
      //   component: () =>
      //     import(
      //       "../modules/conferencia_triagem/view/ConferenciaTriagemView.vue"
      //     ),
      //   meta: {
      //     permission: "conferencia-triagem",
      //   },
      // },
      {
        name: "Conferência de Triagem",
        path: "conferencia-de-triagem",
        component: () =>
          import(
            "../modules/conferencia_triagem_vuetify/view/ConferenciaTriagem.vue"
          ),
        meta: {
          permission:
            store.state.menu.selectedFilial.value === 1
              ? "conferencia-triagem"
              : false,
          hasVuetify: true,
        },
      },
      {
        name: "Auditoria de Volume",
        path: "auditoria-de-volume",
        component: () =>
          import(
            "../modules/auditoria_protocolo/view/AuditoriaProtocoloView.vue"
          ),
        meta: {
          permission:
            store.state.menu.selectedFilial.value === 1
              ? "auditoria-de-volume"
              : false,
        },
      },
      {
        name: "Listagem de Auditoria",
        path: "listagem-de-auditoria",
        component: () =>
          import(
            "../modules/listagem_de_auditoria/view/ListagemDeAuditoriaView.vue"
          ),
        meta: {
          permission:
            store.state.menu.selectedFilial.value === 1
              ? "listagem-de-auditoria"
              : false,
        },
      },
      {
        name: "Conferência de Baia",
        path: "conferencia-de-baia",
        component: () =>
          import(
            "../modules/conferencia_de_baia/view/ConferenciaDeBaiaView.vue"
          ),
        meta: {
          permission:
            store.state.menu.selectedFilial.value === 1
              ? "conferencia-de-baia"
              : false,
        },
      },
    ],
  },
  {
    name: "Planejamento",
    path: "/planejamento",
    component: Vue.component("Planejamento", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      sidebarIcon: "mdi-clipboard-clock-outline",
      isSubmenu: true,
    },
    children: [
      {
        name: "Previsão de Produção",
        path: "previsao-de-producao",
        component: () =>
          import(
            "../modules/producao/previsaoDeProducao/view/PrevisaoDeProducaoView.vue"
          ),
        meta: {
          permission: "previsao-de-producao",
        },
      },
      {
        name: "pedidosWebParaManutencao",
        path: "agendamento-de-producao",
        props: { status: "M" },
        component: () =>
          import(
            "../modules/souserials/listagem_pedidos_manutencao/Manutencao.vue"
          ),
        meta: {
          permission: "agendamento-ss",
          viewName: "Agendamento de Produção",
          hasVuetify: true,
        },
      },
      {
        name: "Supervisão de Pedidos",
        path: "supervisao-de-pedidos",
        component: () =>
          import("../modules/order_supervision/view/OrderSupervision.vue"),
        meta: {
          permission: "supervisao-de-pedidos",
          hasVuetify: true,
        },
      },
    ],
  },
  {
    name: "Pedidos Não Expedir",
    path: "/pedidos-nao-expedir",
    props: {
      status: "NE",
    },
    component: () => import("../modules/nao_expedir/view/NaoExpedirView.vue"),
    meta: {
      sidebarIcon: "mdi-package-variant-closed",
      permission: "nao-expedir",
      hasVuetify: true,
    },
  },
  {
    name: "Pedidos SS",
    path: "/pedidos-ss",
    component: Vue.component("PedidosSS", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      sidebarIcon: "mdi-package-variant-closed",
      isSubmenu: true,
    },
    children: [
      {
        name: "Separação de Painéis",
        path: "separacao-de-paineis",
        component: () =>
          import("../modules/separacao_de_paineis/view/SeparacaoPaineis.vue"),
        meta: {
          hasVuetify: true,
          permission: "separacao-da-linha-1",
        },
      },
      {
        name: "Conferência de Painéis",
        path: "conferencia-de-paineis",
        component: () =>
          import("../modules/panel_inspection/view/PanelInspection.vue"),
        meta: {
          hasVuetify: true,
          permission: "conferencia-painel",
        },
      },
      // {
      //   name: "Separação da Linha 1",
      //   path: "separacao-da-linha-1",
      //   component: () =>
      //     import(
      //       "../modules/separacao_linhas/separacao_linha-1/view/SeparacaoLinha1View.vue"
      //     ),
      //   meta: {
      //     permission: "separacao-da-linha-1",
      //   },
      // },
      {
        name: "pedidosWebAguardandoProducao",
        path: "aguardando-producao",
        props: { status: "A" },
        component: ListingComponent,
        meta: {
          permission: "aguardando-producao-ss",
          viewName: "Aguardando Produção",
          hasVuetify: true,
        },
      },
      // {
      //   name: "Separação da Linha 1",
      //   path: "separacao-da-linha-1",
      //   component: () =>
      //     import(
      //       "../modules/separacao_linhas/separacao_linha-1/view/SeparacaoLinha1View.vue"
      //     ),
      //   meta: {
      //     permission: "separacao-da-linha-1",
      //   },
      // },
      {
        name: "pedidosWebLinhaProducao",
        path: "em-linha-de-producao",
        props: { status: "L" },
        component: ListingComponent,
        meta: {
          permission: "em-linha-de-producao-ss",
          viewName: "Em Linha de Produção",
          hasVuetify: true,
        },
      },
      {
        name: "pedidosWebConferenciaAc",
        path: "em-conferencia-ac",
        props: { status: "ac" },
        component: ListingComponent,
        meta: {
          permission:
            store.state.menu.selectedFilial.value === 1
              ? "em-conferencia-ac-ss"
              : false,
          viewName: "Em Conferência AC",
          hasVuetify: true,
        },
      },
      // {
      //   name: "Aguardando Baia",
      //   path: "aguardando-baia",
      //   component: () =>
      //     import("../modules/aguardando_baia/view/AguardandoBaiaView.vue"),
      //   meta: {
      //     permission: "tela-transporte",
      //   },
      // },
      {
        name: "pedidosWebAguardandoConferencia",
        path: "aguardando-conferencia",
        props: { status: "N" },
        component: ListingComponent,
        meta: {
          permission: "em-aguardando-conferencia-ss",
          viewName: "Aguardando Conferência",
          hasVuetify: true,
        },
      },
      {
        name: "pedidosWebEmConferencia",
        path: "em-conferencia-final",
        props: { status: "F" },
        component: ListingComponent,
        meta: {
          permission: "em-conferencia-final-ss",
          viewName: "Em Conferência Final",
          hasVuetify: true,
        },
      },
      {
        name: "Com Problema",
        path: "com-problema",
        props: { status: "T" },
        component: ListingComponent,
        meta: {
          permission: "pedido-com-problema-ss",
          hasVuetify: true,
        },
      },
      // {
      //   name: "pedidosWebAguardandoEmbarque",
      //   path: "aguardando-embarque",
      //   props: { status: "E" },
      //   component: ListingComponent,
      //   meta: {
      //     permission: "aguardando-embarque-ss",
      //     viewName: "Aguardando Embarque",
      //     hasVuetify: true,
      //   },
      // },
      // {
      //   name: "pedidosWebDespachados",
      //   path: "despachados",
      //   props: { status: "D" },
      //   component: ListingComponent,
      //   meta: {
      //     permission: "despachados",
      //     viewName: "Despachados",
      //     hasVuetify: true,
      //   },
      // },
      // {
      //   name: "pedidosWebCancelados",
      //   path: "cancelados",
      //   props: { status: "C" },
      //   component: ListingComponent,
      //   meta: {
      //     permission: "cancelados",
      //     viewName: "Cancelados",
      //     hasVuetify: true,
      //   },
      // },
    ],
  },
  // {
  //   name: "Pedidos Ágil",
  //   path: "/pedidos-agil",
  //   component: Vue.component("PedidosAgil", {
  //     ...compileToFunctions("<router-view></router-view>"),
  //   }),
  //   meta: {
  //     sidebarIcon: "mdi-package-variant-closed",
  //     isSubmenu: true,
  //   },
  //   children: [
  //     {
  //       name: "Agendamento de Produção",
  //       path: "agendamento-de-producao",
  //       component: AgendamentoExpress,
  //       meta: {
  //         permission: "agendamento-agil",
  //       },
  //     },
  //     {
  //       name: "pedidosWebLinhaProducaoExpress",
  //       path: "em-linha-de-producao",
  //       props: { status: "L" },
  //       component: ListingComponentExpress,
  //       meta: {
  //         permission: "em-linha-de-producao-agil",
  //         viewName: "Em Linha de Produção",
  //       },
  //     },
  //     {
  //       name: "pedidosExpressConferenciaAc",
  //       path: "em-conferencia-ac",
  //       props: { status: "ac" },
  //       component: ListingComponentExpress,
  //       meta: {
  //         permission: "em-conferencia-ac-agil",
  //         viewName: "Em Conferência AC",
  //       },
  //     },
  //     {
  //       name: "Aguardando Conferência",
  //       path: "aguardando-conferencia",
  //       props: { status: "N" },
  //       component: ListingComponentExpress,
  //       meta: {
  //         permission: "em-aguardando-conferencia-agil",
  //       },
  //     },
  //     {
  //       name: "pedidosExpressConferencia",
  //       path: "em-conferencia-final",
  //       props: { status: "F" },
  //       component: ListingComponentExpress,
  //       meta: {
  //         permission: "em-conferencia-final-agil",
  //         viewName: "Em Conferência Final",
  //       },
  //     },
  //     {
  //       name: "Com Problema",
  //       path: "com-problema",
  //       props: { status: "T" },
  //       component: ListingComponentExpress,
  //       meta: {
  //         permission: "pedido-com-problema-agil",
  //       },
  //     },
  //     {
  //       name: "Finalizados",
  //       path: "finalizados",
  //       props: { status: "D" },
  //       component: ListingComponentExpress,
  //       meta: {
  //         permission: "finalizados",
  //       },
  //     },
  //   ],
  // },
  {
    name: "Pedidos Diversos",
    path: "/pedidos-diversos",
    component: Vue.component("PedidosDiversos", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      sidebarIcon: "mdi-package-variant-closed",
      isSubmenu: true,
    },
    children: [
      {
        name: "Em Linha de Produção SS",
        path: "em-linha-de-producao-ss",
        props: { status: "O" },
        component: ListingComponent,
        meta: {
          permission: "em-linha-de-producao-diversos-ss",
          hasVuetify: true,
        },
      },
      // {
      //   name: "Em Linha de Produção Ágil",
      //   path: "em-linha-de-producao-agil",
      //   props: { status: "O" },
      //   component: ListingComponentExpress,
      //   meta: {
      //     permission: "em-linha-de-producao-diversos-agil",
      //   },
      // },
    ],
  },
  // {
  //   name: "Lead Time",
  //   path: "/lead-time",
  //   component: () => import("../modules/lead_time/view/LeadTime.vue"),
  //   meta: {
  //     permission: "tela-de-administracao",
  //     sidebarIcon: "mdi-clock-outline",
  //     hasVuetify: true,
  //   },
  // },
  {
    name: "Configurações",
    path: "/configuracoes",
    component: Vue.component("Configuracoes", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      sidebarIcon: "mdi-cog-outline",
      isSubmenu: true,
    },
    children: [
      {
        name: "Mesas de Solo",
        path: "mesas-de-solo",
        component: () =>
          import("../modules/ground_mounts/view/GroundMounts.vue"),
        meta: {
          permission: "configuracao-mesa-solo",
          hasVuetify: true,
        },
      },
      {
        name: "Baias de Triagem",
        path: "baias-de-triagem",
        component: () =>
          import("../modules/sorting_bays_vuetify/view/SortingBays.vue"),
        meta: {
          permission: "configuracao-de-baia",
          hasVuetify: true,
        },
      },
      {
        name: "Insumos",
        path: "insumos",
        component: () =>
          import("../modules/insumos/view/ConfiguracaoDeInsumoView.vue"),
        meta: {
          permission: "configuracao-de-baia",
          hasVuetify: true,
        },
      },
      {
        name: "users",
        path: "usuarios",
        component: () => import("../modules/usuarios//view/UsuariosView.vue"),
        meta: {
          permission: "usuarios",
          viewName: "Usuários",
          hasVuetify: true,
        },
      },
      {
        name: "roles",
        path: "perfis",
        component: () => import("../modules/roles_vuetify/view/Roles.vue"),
        meta: {
          permission: "funcoes",
          viewName: "Perfis",
          hasVuetify: true,
        },
      },
      {
        name: "local",
        path: "no-estoque",
        component: () => import("../modules/localizacao_estoque/List.vue"),
        meta: {
          permission: "tela-no-estoque",
          viewName: "No Estoque",
        },
      },
    ],
  },
];

const routes = [
  ...sidebarViews,
  /* General routes */
  {
    path: "/login",
    name: "Login",
    component: () => import("../modules/login/Login.vue"),
    meta: {
      hasVuetify: true,
    },
  },
  // { path: "/", name: "Home",  component: Login },

  /* Roles routes */
  {
    name: "new_roles",
    path: "/roles/new",
    meta: { viewName: "Criar Função" },
    component: NewRoles,
  },
  {
    name: "edit_roles",
    path: "/roles/edit/:id",
    component: EditRoles,
    meta: { viewName: "Editar Função" },
  },

  /* Users routes */
  {
    name: "new_users",
    path: "/users/new",
    component: NewUsers,
    meta: { viewName: "Criar Usuário" },
  },
  {
    name: "edit_users",
    path: "/users/edit/:id",
    component: EditUsers,
    meta: { viewName: "Editar Usuário" },
  },

  /* Local routes */
  {
    name: "new_local",
    path: "/local/new",
    component: NewLocal,
    meta: { viewName: "Criar Localização" },
  },
  {
    name: "edit_local",
    path: "/local/edit/:id",
    component: EditLocal,
    meta: { viewName: "Editar Localização" },
  },

  /* Old ss orders listing routes */
  {
    name: "aguardando-prod",
    path: "/aguardando-prod",
    redirect: { name: "pedidosWebAguardandoProducao" },
  },
  {
    name: "linha-prod",
    path: "/linha-prod",
    redirect: { name: "pedidosWebLinhaProducao" },
  },
  {
    name: "aguardando-confere",
    path: "/aguardando-confere",
    redirect: { name: "pedidosWebAguardandoConferencia" },
  },
  {
    name: "confere",
    path: "/confere",
    redirect: { name: "pedidosWebEmConferencia" },
  },
  {
    name: "confereac",
    path: "/confereac",
    redirect: { name: "pedidosWebConferenciaAc" },
  },
  {
    name: "cancelado",
    path: "/cancelado",
    redirect: { name: "pedidosWebCancelados" },
  },
  {
    name: "despachado",
    path: "/despachado",
    redirect: { name: "pedidosWebDespachados" },
  },
  {
    name: "embarque",
    path: "/embarque",
    redirect: { name: "pedidosWebAguardandoEmbarque" },
  },
  {
    name: "manutencao-prod",
    path: "/manutencao-prod/",
    redirect: { name: "pedidosWebParaManutencao" },
  },

  /* Old ss production order routes */
  {
    name: "pedido-confere",
    path: "/pedido-confere/:id",
    component: PedidoConfere,
    meta: { viewName: "Conferência Final" },
  },
  {
    name: "pedido-confere-ac",
    path: "/pedido-confere-ac/:id",
    component: PedidoConfereAC,
    meta: { viewName: "Em Conferência AC" },
  },
  {
    name: "pedido-confere-det",
    path: "/pedido-confere-det/:id",
    component: PedidoConfereDet,
  },
  {
    name: "pedido-resumo",
    path: "/pedido-resumo/:id",
    component: PedidoResumo,
    meta: { viewName: "Resumo de Pedido" },
  },
  {
    name: "pedido-embarque",
    path: "/pedido-embarque/:id",
    component: PedidoEmbarque,
    meta: { viewName: "Embarque de Pedido" },
  },
  // { name: "pedido-confere-com-problema", path: "/pedido-confere-com-problema/:id", component: ConfereComProblema },

  /* Old ss production order routes */
  {
    name: "pedido",
    path: "/pedido/:id",
    redirect: (to) => ({
      name: "pedidoWebProducaoSelecaoPaineis",
      params: { numped: to.params.id },
    }),
  },

  /* New ss production order routes */
  {
    name: "pedidoWebProducaoSelecaoPaineis",
    path: "/pedido/:numped/selecao-linhas",
    component: PedidoWebProducaoSelecaoLinhas,
    meta: { viewName: "Seleção de Linhas" },
  },
  {
    name: "pedidoLinhaPaineis",
    path: "/pedido/:numped/l1",
    component: PedidoWebProducaoLinhaPaineis,
    meta: { viewName: "Linha 1" },
  },
  {
    name: "pedidoLinhaCabos",
    path: "/pedido/:numped/l2",
    component: PedidoWebProducaoLinhaCabos,
    meta: { viewName: "Linha 2" },
  },
  {
    name: "pedidoLinhaDiversos",
    path: "/pedido/:numped/l3",
    component: PedidoWebProducaoLinhaDiversos,
    meta: { viewName: "Linha 3" },
  },
  {
    name: "pedidoLinhaPerfis",
    path: "/pedido/:numped/l4",
    component: PedidoWebProducaoLinhaPerfis,
    meta: { viewName: "Linha 4" },
  },

  {
    name: "pedidoLinhaInversores",
    path: "/pedido/:numped/l5",
    component: PedidoWebProducaoLinhaInversores,
    meta: { viewName: "Linha 5" },
  },

  /*Filial Pará Linhas*/

  {
    name: "pedidoLinhaPaineisPara",
    path: "/pedido-para/:numped/l1",
    component: () =>
      import(
        "@/modules/souserials/producao_pedido/linhas_producao/filial_para/linha_1/view/FilialParaProducaoLinhaPaineis.vue"
      ),
    meta: { viewName: "Linha 1 (Filial Pará)" },
  },

  {
    name: "pedidoLinhaCabosPara",
    path: "/pedido-para/:numped/l2",
    component: () =>
      import(
        "@/modules/souserials/producao_pedido/linhas_producao/filial_para/linha_2/view/FilialParaPedidoWebProducaoLinhaCabos.vue"
      ),
    meta: { viewName: "Linha 2 (Filial Pará)" },
  },

  {
    name: "pedidoLinhaDiversosPara",
    path: "/pedido-para/:numped/l3",
    component: () =>
      import(
        "@/modules/souserials/producao_pedido/linhas_producao/filial_para/linha_3/view/FilialParaPedidoWebProducaoLinhaDiversos.vue"
      ),
    meta: { viewName: "Linha 3 (Filial Pará)" },
  },

  {
    name: "pedidoLinhaPerfisPara",
    path: "/pedido-para/:numped/l4",
    component: () =>
      import(
        "@/modules/souserials/producao_pedido/linhas_producao/filial_para/linha_4/view/FilialParaPedidoWebProducaoLinhaPerfis.vue"
      ),
    meta: { viewName: "Linha 4 (Filial Pará)" },
  },

  {
    name: "pedidoLinhaInversoresPara",
    path: "/pedido-para/:numped/l5",
    component: () =>
      import(
        "@/modules/souserials/producao_pedido/linhas_producao/filial_para/linha_5/view/FilialParaProducaoLinhaInversores.vue"
      ),
    meta: { viewName: "Linha 5 (Filial Pará)" },
  },

  /* New ss listing order routes */
  // {
  //   name: "pedidosWebAguardandoProducao",
  //   path: "/pedidos-web/aguardando-producao",
  //   props: { status: "A" },
  //   component: ListingComponent,
  //   beforeEnter: (to, from, next) => {
  //     if (roles.includes("LINHA1") || roles.includes("ADMIN")) {
  //       next();
  //     } else {
  //       next("/dashboard");
  //     }
  //   },
  // },

  /* Admin routes */
  {
    name: "gerenciar-pedido",
    path: "/pedido/:id/gerenciar-pedido/",
    component: GerenciarPedido,
    meta: { viewName: "Gerenciar Pedido", hasVuetify: true },
  },
  {
    name: "editar-pedido",
    path: "/pedido/:numped/editar-pedido/",
    component: EscolheLinhaEditarPedido,
    meta: { viewName: "Editar Pedido" },
  },
  {
    name: "editarPedidoLinhaPaineis",
    path: "/editar-pedido/:numped/l1",
    component: EditarLinhaPaineis,
    meta: { viewName: "Editar Linha 1" },
  },
  {
    name: "editarPedidoLinhaDiversos",
    path: "/editar-pedido/:numped/l3",
    component: EditarLinhaDiversos,
    meta: { viewName: "Editar Linha 3" },
  },
  // {
  //   name: "pedidosWebAguardandoProducao",
  //   path: "/pedidos-agil/aguardando-producao",
  //   props: { status: "A" },
  //   component: ListingComponentExpress,
  //   beforeEnter: (to, from, next) => {
  //     if (roles.includes("LINHA1") || roles.includes("ADMIN")) {
  //       next();
  //     } else {
  //       next("/dashboard");
  //     }
  //   },
  // },
  // {
  //   name: "pedidosExpressConferenciaAc",
  //   path: "/pedidos-agil/em-conferencia-ac",
  //   props: { status: "ac" },
  //   component: ListingComponentExpress,
  // },
  {
    name: "PedidoWebProducaoSelecaoLinhasExpress",
    path: "/pedido-agil/:numped/selecao-linhas",
    component: PedidoWebProducaoSelecaoLinhasExpress,
    meta: { viewName: "Seleção de Linhas" },
  },
  {
    name: "pedidoLinhaPaineisExpress",
    path: "/pedido/:numped/l1",
    component: PedidoWebProducaoLinhaPaineisExpress,
    meta: { viewName: "Linha 1" },
  },
  {
    name: "pedidoLinhaCabosExpress",
    path: "/pedido/:numped/l2",
    component: PedidoWebProducaoLinhaCabosExpress,
    meta: { viewName: "Linha 2" },
  },
  {
    name: "pedidoLinhaDiversosExpress",
    path: "/pedido/:numped/l3",
    component: PedidoWebProducaoLinhaDiversosExpress,
    meta: { viewName: "Linha 3" },
  },
  {
    name: "pedidoLinhaPerfisExpress",
    path: "/pedido/:numped/l4",
    component: PedidoWebProducaoLinhaPerfisExpress,
    meta: { viewName: "Linha 4" },
  },
  // {
  //   name: "pedidoLinhaInversoresExpress",
  //   path: "/pedido-agil/:numped/l5",
  //   component: PedidoWebProducaoLinhaInversoresExpress,
  // },

  {
    name: "pedido-confere-agil",
    path: "/pedido-confere-agil/:id",
    component: PedidoConfereExpress,
  },
  {
    name: "pedido-confere-agil-ac",
    path: "/pedido-confere-agil-ac/:id",
    component: PedidoConfereACExpress,
  },
  {
    name: "pedidosExpressAguardandoEmbarque",
    path: "/pedidos-agil/aguardando-embarque",
    props: { status: "E" },
    component: ListingComponentExpress,
  },
  {
    name: "pedidosExpressCancelados",
    path: "/pedidos-agil/cancelados",
    props: { status: "C" },
    component: ListingComponentExpress,
  },
  {
    name: "pedido-embarque-agil",
    path: "/pedido-embarque-agil/:id",
    component: PedidoEmbarqueExpress,
  },
  /* Pedidos Não Expedir */
  // {
  //   name: "pedidosNaoExpedir",
  //   path: "/pedidos-nao-expedir",
  //   props: {
  //     status: "NE",
  //   },
  //   component: ListingComponentNaoExpedir,
  // },
  // {
  //   name: "Configuração de Insumos",
  //   path: "/configuracoes/insumos",
  //   component: ConfiguracaoDeInsumosView,
  // },
  // {
  //   name: "Configuração de Baias",
  //   path: "/configuracoes/baias",
  //   component: ConfiguracoesBaiasView,
  // },

  /* Redirect to dashboard if route has not found */
  { path: "*", redirect: { name: "dashboard" } },
];

const router = new VueRouter({
  mode: "history",
  base: "",
  routes,
});

router.beforeEach((to, from, next) => {
  const userPermissions = getUserLogged().permissoes;
  const routePermission = to?.meta?.permission;
  const isRouteSubmenu = to?.meta?.isSubmenu ?? false;
  const routeNeedsPermission = to?.meta?.permission ?? false;
  const userHasPermission = userPermissions.includes(routePermission);

  if (!isRouteSubmenu) {
    if (routeNeedsPermission) {
      if (userHasPermission) {
        next();
        return;
      }
      next("/dashboard");
      return;
    }
    next();
    return;
  }
  next("/dashboard");
  return;
});

export default router;
