export default {
  drawer: true,
  darkMode: false,
  environment: "development",
  selectedFilial: { label: "Matriz - Ceará", value: 1 },
  filiaisList: [
    { label: "Matriz - Ceará", value: 1 },
    { label: "Filial - Pará", value: 3 },
  ],
};
