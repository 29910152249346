import router from "../../../router";
import store from "../../../store/store";

export default class SelecaoLinhasService {
  numped = "";
  filial = store.state.menu.selectedFilial.value;

  static build() {
    return new SelecaoLinhasService();
  }

  setNumped(numped) {
    this.numped = numped;
    return this;
  }

  getRouteMap() {
    return {
      // Filial Ceará
      1: {
        paineis: "pedidoLinhaPaineis",
        cabos: "pedidoLinhaCabos",
        diversos: "pedidoLinhaDiversos",
        perfis: "pedidoLinhaPerfis",
        inversores: "pedidoLinhaInversores",
      },
      // Filial Pará
      3: {
        paineis: "pedidoLinhaPaineisPara",
        cabos: "pedidoLinhaCabosPara",
        diversos: "pedidoLinhaDiversosPara",
        perfis: "pedidoLinhaPerfisPara",
        inversores: "pedidoLinhaInversoresPara",
      },
    };
  }

  async navigateTo(line) {
    const routeMap = this.getRouteMap()[this.filial] || this.getRouteMap()[1];
    const routeName = routeMap[line];

    if (routeName) {
      await router.replace({
        name: routeName,
        params: { numped: this.numped },
      });
    } else {
      console.error("Linha não configurada para a filial atual.");
    }
  }

  async goToLinhaPaineis() {
    await this.navigateTo("paineis");
  }

  async goToLinhaCabos() {
    await this.navigateTo("cabos");
  }

  async goToLinhaDiversos() {
    await this.navigateTo("diversos");
  }

  async goToLinhaPerfis() {
    await this.navigateTo("perfis");
  }

  async goToLinhaInversores() {
    await this.navigateTo("inversores");
  }

  // Não é mais usado as linhas express. - Remover do projeto

  async goToLinhaPaineisExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaPaineisExpress",
      params: { numped },
    });
  }

  async goToLinhaCabosExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaCabosExpress",
      params: { numped },
    });
  }

  async goToLinhaDiversosExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaDiversosExpress",
      params: { numped },
    });
  }

  async goToLinhaPerfisExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaPerfisExpress",
      params: { numped },
    });
  }

  async goToLinhaInversoresExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaInversoresExpress",
      params: { numped },
    });
  }
}
