export class UsuarioLogado {
  static setUserId(userId) {
    localStorage.setItem("userId", userId);
  }

  static setPcEmprId(pcEmprId) {
    localStorage.setItem("pcEmprId", pcEmprId);
  }

  static setRoles(roles) {
    localStorage.setItem("roles", roles);
  }

  static setPerfil(perfil) {
    localStorage.setItem("perfil", perfil);
  }

  static setUnidadeId(unidadeId) {
    localStorage.setItem("unidadeId", unidadeId);
  }

  static getPerfil() {
    return localStorage.getItem("perfil");
  }

  static getUserId() {
    return +localStorage.getItem("userId") || 0;
  }

  static getRoles() {
    return localStorage.getItem("roles") || [];
  }

  static getPcEmprId() {
    return +localStorage.getItem("pcEmprId") || 0;
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static isLogged() {
    return !!UsuarioLogado.getToken();
  }

  static isAdmin() {
    return UsuarioLogado.getPerfil().includes("administrador");
  }

  static setPermissoes(permissoes) {
    localStorage.setItem("permissoes", permissoes);
  }

  static getPermissoes() {
    return localStorage.getItem("permissoes");
  }

  static getUnidadeId() {
    return localStorage.getItem("unidadeId");
  }
}
