import { HttpRestService } from "@/services/http/HttpRestService";
import store from "../store";

export default class Repository {
  static getVolumeDetails(body) {
    return HttpRestService.post(
      `/api/v2/pedido-web/separacao/iniciar-separacao`,
      body
    );
  }
  static finalizaPedido(body) {
    return HttpRestService.post(
      `/api/v2/pedido-web/separacao/finalizar-separacao`,
      body
    );
  }
  static getSeparadores() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(`/api/user-sep-linha1?unidadeId=${unidadeId} `);
  }
}
