<template>
  <v-container fluid>
    <listing-header
      title="Aguardando Produção"
      :relatorioBtn="pedidosStatus === 'A'"
      :uploadBtn="pedidosStatus === 'A' || pedidosStatus === 'L'"
      @filter="filter"
      :pesquisarSerial="pedidosStatus === ''"
      :isButtonLoading="isButtonLoading"
      :pedidosStatus="pedidosStatus"
    />
    <default-table-head class="d-none d-md-flex">
      <v-col cols="2" class="border-r">
        <span>Pedido</span>
      </v-col>
      <v-col cols="3" class="border-r">
        <span>Cliente</span>
      </v-col>
      <v-col
        :cols="isBlockStatusVisible ? '2' : '3'"
        class="border-r"
      >
        <span>Transportadora</span>
      </v-col>
      <v-col
        v-if="isBlockStatusVisible"
        cols="1"
        class="border-r"
      >
        <span>Status</span>
      </v-col>
    </default-table-head>
    <template v-if="!listagemPedidosService.loading">
      <div v-if="!pedidos.length">
        <v-card class="py-7 background" elevation="0">
          <v-icon large color="red" class="mr-4">
            mdi-alert-circle-outline
          </v-icon>
          <span class="font-weight-bold text-uppercase">
            Nenhum resultado encontrado para hoje.
          </span>
        </v-card>
      </div>
      <div v-else>
        <listing-card
          v-for="(pedido, index) in pedidos"
          :key="index"
          @openModalIniciarProducao="openModalIniciarProducao"
          @open-details="modalDetalhes = $event"
          :pedido="pedido"
        />
        <v-col>
          <default-pagination
            v-model="pagination.currentPage"
            :length="pagination.lastPage"
            @input="paginate(pagination.currentPage)"
            :disabled="pedidos.length === 0"
          />
        </v-col>
      </div>
    </template>
    <v-col v-else>
      <default-spinner />
    </v-col>
    <modal-detalhes-pedido
      v-if="modalDetalhes.relacaoTiposInsumoQuantidade"
      :pedido="modalDetalhes.pedido"
      :relacao-tipos-insumo-quantidade="
        modalDetalhes.relacaoTiposInsumoQuantidade
      "
    />
    <modal-iniciar-producao
      :separadores="separadores"
      :localizacoesEstoque="localizacoesEstoque"
      :numeroPedido="numeroPedidoModal"
    />
    <modal-etiquetas relacao-tipos-insumo-quantidade="" pedido="" />
    <modal-total-etiquetas />
    <modal-upload />
    <modal-pesquisar-serial
      :pedido="pedido"
      :serialString="serialString"
      :isLoading="isLoading"
      @getPedidoPeloSerial="getPedidoPeloSerial"
    />
  </v-container>
</template>

<script>
import { ParametrosIniciarProducaoPedido } from "../../../services/iniciar_producao/ParametrosIniciarProducaoPedido";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination.vue";
import DefaultSpinner from "../../../shared/components/vuetify/DefaultSpinner.vue";
import DefaultTableHead from "../../../shared/components/vuetify/DefaultTableHead.vue";
import ListagemPedidosServices from "../../../services/listagem_pedidos/ListagemPedidosService.js";
import ListingHeader from "./ListingHeader";
import ModalDetalhesPedido from "./ModalDetalhesPedido";
import ModalIniciarProducao from "./ModalIniciarProducao.vue";
import ModalEtiquetas from "./ModalEtiquetas";
import ListingCard from "./ListingCard";
import ModalTotalEtiquetas from "./ModalTotalEtiquetas";
import ModalUpload from "./ModalUpload";

import ModalPesquisarSerial from "./ModalPesquisarSerial.vue";

export default {
  name: "ListingComponent",
  components: {
    DefaultPagination,
    DefaultSpinner,
    DefaultTableHead,
    ModalDetalhesPedido,
    ModalEtiquetas,
    ModalTotalEtiquetas,
    ModalUpload,
    ListingHeader,
    ListingCard,
    ModalIniciarProducao,
    ModalPesquisarSerial,
  },
  data: () => ({
    pedido: null,
    isLoading: false,
    isButtonLoading: false,
    serialString: null,
    page: 1,
    pedidos: [],
    separadores: [],
    localizacoesEstoque: [],
    numeroPedidoModal: 0,
    pagination: {
      total: 0,
      perPage: 0,
      currentPage: 1,
      lastPage: 0,
      previousPage: 0,
      nextPage: 0,
    },
    filtro: { dataInicio: "", dataFinal: "" },
    parametrosIniciarProducaoPedido: ParametrosIniciarProducaoPedido.build(),
    listagemPedidosService: ListagemPedidosServices.build(),
    modalDetalhes: {
      relacaoTiposInsumoQuantidade: [],
      pedido: {},
    },
    telaTemFiltros: false,
  }),
  beforeMount() {
    this.getDate();
  },
  created() {
    this.listagemPedidosService = ListagemPedidosServices.build().setVm(this);
    this.parametrosIniciarProducaoPedido =
      ParametrosIniciarProducaoPedido.build().setVm(this);
  },
  async mounted() {
    await this.listagemPedidosService
      .setStatus(this.pedidosStatus)
      .list(this.page, this.filtro);
    await this.parametrosIniciarProducaoPedido.run();
  },
  watch: {
    async pedidosStatus() {
      await this.listagemPedidosService
        .setStatus(this.pedidosStatus)
        .list(this.page, this.filtro);
    },
  },
  methods: {
    getDate() {
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1;
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      this.filtro.dataInicio = year + "-" + month + "-" + day;
      this.filtro.dataFinal = year + "-" + month + "-" + day;
    },
    async paginate(page) {
      await this.listagemPedidosService.list(page, this.filtro);
    },
    async filter(params) {
      this.isButtonLoading = true;
      this.filtro = params;
      await this.listagemPedidosService.list(
        this.pagination.currentPage,
        this.filtro
      );
      this.isButtonLoading = false;
    },
    async getPedidoPeloSerial(numeroPedido) {
      this.isLoading = true;
      await this.listagemPedidosService.getPedidoPeloSerial(numeroPedido);
    },
    openModalIniciarProducao(numeroPedido) {
      this.numeroPedidoModal = numeroPedido;
      // if (perfil === "administrador") {
      //   this.$router.replace({
      //     name: "pedidoWebProducaoSelecaoPaineis",
      //     params: {
      //       numped: numeroPedido,
      //     },
      //   });
      //   return;
      // }
      this.$bvModal.show("modal-inicar-producao");
    },
  },
  computed: {
    pedidosStatus() {
      return this.$attrs.status;
    },
    isBlockStatusVisible() {
      switch (this.pedidosStatus) {
        case "":
        case "F":
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style scoped></style>
