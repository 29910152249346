import Repository from "../repository";
import getOriginalState from "./state";
import { ErrorNotifier } from "@/services/messages/ErrorNotifier";

let requestCounter = 0;

function isMostRecentRequest(currentRequestId) {
  return currentRequestId === requestCounter;
}

function findTargetSubTabIndex(filterParams, subTabs) {
  return subTabs.findIndex((subTab) => {
    return Object.keys(filterParams).some((propName) => {
      return Object.values(subTab.exclusiveFilters).includes(propName);
    });
  });
}

export default {
  async listing({ commit, dispatch, getters }) {
    const currentRequestId = ++requestCounter;
    commit("setIsLoadingListing", true);
    commit("setListing", []);
    try {
      const response = await Repository.getListing(
        getters.getCurrentSubTab.endpoint,
        getters.getUrlParams
      );
      if (response.status === 200) {
        if (isMostRecentRequest(currentRequestId)) {
          commit("setListing", response.data.data.list);
          commit("setPagination", response.data.data.pagination);
        }
        return;
      }
      throw response;
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      if (isMostRecentRequest(currentRequestId)) {
        commit("setIsLoadingListing", false);
      }
    }
  },
  async orderOverview({ commit, dispatch }, { winthorOrder, setIsLoading }) {
    setIsLoading(true);
    commit("setOrderOverview", {});
    const currentRequestId = ++requestCounter;
    try {
      const response = await Repository.getOrderOverview(winthorOrder);
      if (isMostRecentRequest(currentRequestId)) {
        if (response.status === 200) {
          commit("setOrderOverview", response.data.data);
          return response;
        }
        throw response;
      }
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      if (isMostRecentRequest(currentRequestId)) {
        setIsLoading(false);
      }
    }
  },
  async stageDuration({ commit, dispatch }, { winthorOrder, setIsLoading }) {
    setIsLoading(true);
    commit("setStageDuration", {});
    const currentRequestId = ++requestCounter;
    try {
      const response = await Repository.getStageDuration(winthorOrder);
      if (isMostRecentRequest(currentRequestId)) {
        if (response.status === 200) {
          commit("setStageDuration", response.data.data[0]);
          return response;
        }
        throw response;
      }
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      if (isMostRecentRequest(currentRequestId)) {
        setIsLoading(false);
      }
    }
  },
  async inputDetails(
    { commit, dispatch },
    { code, listsByVolume, setIsLoading }
  ) {
    setIsLoading(true);
    commit("setInputDetails", []);
    const currentRequestId = ++requestCounter;
    try {
      const response = await Repository.getInputDetails(code, listsByVolume);
      if (isMostRecentRequest(currentRequestId)) {
        if (response.status === 200) {
          commit("setInputDetails", response.data.data);
          return response;
        }
        throw response;
      }
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      if (isMostRecentRequest(currentRequestId)) {
        setIsLoading(false);
      }
    }
  },
  async volumeDetails({ commit, dispatch }, { winthorOrder, setIsLoading }) {
    setIsLoading(true);
    commit("setVolumeDetails", {});
    const currentRequestId = ++requestCounter;
    try {
      const response = await Repository.getVolumeDetails(winthorOrder);
      if (isMostRecentRequest(currentRequestId)) {
        if (response.status === 200) {
          commit("setVolumeDetails", response.data.data);
          return response;
        }
        throw response;
      }
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      if (isMostRecentRequest(currentRequestId)) {
        setIsLoading(false);
      }
    }
  },
  async sortingDetails(
    { commit, dispatch },
    { orderId, operationId, setIsLoading }
  ) {
    setIsLoading(true);
    commit("setSortingDetails", []);
    const currentRequestId = ++requestCounter;
    try {
      const response = await Repository.getSortingDetails(orderId, operationId);
      if (isMostRecentRequest(currentRequestId)) {
        if (response.status === 200) {
          commit("setSortingDetails", response.data.data);
          return response;
        }
        throw response;
      }
    } catch (error) {
      dispatch("errorMessage", error);
    } finally {
      if (isMostRecentRequest(currentRequestId)) {
        setIsLoading(false);
      }
    }
  },
  async generateOrdersReport(
    { commit, dispatch },
    { body, fileName, fileFormat }
  ) {
    try {
      const response = await Repository.generateOrdersReport(
        body,
        fileName,
        fileFormat
      );
      if (response?.status === 200) {
        commit(
          "notifications/setSuccessNotification",
          {
            successStatus: true,
            successMessage: "Relatório gerado com sucesso",
          },
          { root: true }
        );
        return;
      }
      throw {
        data: {
          message: response.message,
        },
        status: response.statusCode,
      };
    } catch (error) {
      dispatch("errorMessage", error);
    }
  },
  setCurrentMainTabIndex({ commit }, payload) {
    commit("setCurrentMainTabIndex", payload);
  },
  setCurrentSubTabIndex({ commit }, payload) {
    commit("setCurrentSubTabIndex", payload);
  },
  setUrlParams({ commit }, payload) {
    commit("setUrlParams", payload);
  },
  setFilterParams({ commit, state }, payload) {
    commit("setUrlParams", {
      ...state.urlParams,
      filterParams: payload,
    });
  },
  resetUrlParams({ commit }) {
    const originalState = getOriginalState();
    commit("setUrlParams", originalState.urlParams);
  },
  resetState({ commit }) {
    const originalState = getOriginalState();
    commit("resetState", originalState);
  },
  redirectToTargetSubTab({ state, getters, commit }) {
    const currentSubTabIndex = state.currentSubTabIndex;
    const filterParams = state.urlParams.filterParams;
    const currentMainTabSubTabs = getters.getCurrentMainTab.subTabs;
    const targetSubTabIndex = findTargetSubTabIndex(
      filterParams,
      currentMainTabSubTabs
    );
    commit(
      "setCurrentSubTabIndex",
      targetSubTabIndex >= 0 ? targetSubTabIndex : currentSubTabIndex
    );
  },
  setCurrentPage({ commit, state }, payload) {
    commit("setUrlParams", {
      ...state.urlParams,
      defaultParams: {
        ...state.urlParams.defaultParams,
        page: payload,
      },
    });
  },
  filter({ dispatch }) {
    dispatch("setCurrentPage", 1);
    dispatch("redirectToTargetSubTab");
    dispatch("listing");
  },
  errorMessage({ commit, dispatch }, payload) {
    ErrorNotifier.run(commit, dispatch, payload);
  },
};
